// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { dailyStoicQuote } from '../../utils/motivation-phrases';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box component="img" src="/assets/illustrations/illustration_docs.svg" />
        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
            {`${dailyStoicQuote()?.text}`}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            {`${dailyStoicQuote()?.author}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
            V 3.0.37
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
